import { useTranslation } from 'next-i18next';

import type { MouseEventHandler } from 'react';
import tw from 'twin.macro';

import Image from '@components/elements/Image/Image';

type BasketDietTileProps = {
  isDisabled: boolean;
  imageUrl: string;
  offerName: string;
  customName: string;
  variant: string;
  calorific: string;
  dateFrom: string;
  dateTo: string;
  dietLength: number;
  onClick: MouseEventHandler<HTMLButtonElement>;
};

const BasketDietTile = ({
  isDisabled,
  imageUrl,
  offerName,
  customName,
  variant,
  calorific,
  dateFrom,
  dateTo,
  dietLength,
  onClick,
}: BasketDietTileProps) => {
  const { t } = useTranslation();

  return (
    <button
      onClick={onClick}
      tw="w-full flex rounded-lg border border-gray-1 transition-colors duration-300 cursor-pointer hover:(border-primary duration-300) overflow-hidden not-last:mb-4"
      css={isDisabled && tw`opacity-50 pointer-events-none`}
      role="button"
      tabIndex={0}
    >
      <div tw="min-w-24 min-h-24 rounded-md overflow-hidden">
        <Image
          src={imageUrl}
          alt={offerName}
          width={96}
          height={96}
          style={{ objectFit: 'cover', height: '100%' }}
        />
      </div>
      <div tw="text-left p-3">
        <h3 tw="text-lg mb-0.5">
          {offerName}
          {customName && ` - ${customName}`}
        </h3>
        <p tw="m-0 text-sm font-medium">
          {variant}, {calorific}
        </p>
        <p tw="m-0 text-sm font-medium">
          {dateFrom} - {dateTo} ({dietLength}{' '}
          {t('$*basket.CountDayInformation')})
        </p>
      </div>
    </button>
  );
};

export default BasketDietTile;
