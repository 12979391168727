import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import tw from 'twin.macro';

import TimesIcon from '@assets/icons/TimesIcon';
import Image from '@components/elements/Image/Image';
import { cssMerge } from '@utils/styleHelpers';

const Tag = ({
  color = 'default',
  label = '',
  onClick,
  isRemovable = false,
  variant = 'default',
  styles = {},
  iconSrc = '',
  isSelected = false,
  ...restProps
}) => {
  const variantStyle = {
    default: {
      main: tw`border`,
      color: {
        default: tw`text-gray-2 bg-gray-0 border-gray-0`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
      colorHover: {
        default: tw`hover:(bg-gray-1 border-gray-1)`,
        primary: tw`hover:(bg-primary-hover border-primary-hover)`,
        secondary: tw`hover:(bg-primary border-primary)`,
      },
      colorSelected: {
        default: tw`bg-gray-1 border-gray-1`,
        primary: tw`bg-primary-hover border-primary-hover`,
        secondary: tw`bg-primary border-primary`,
      },
    },
    outline: {
      main: tw`border`,
      color: {
        default: tw`text-gray-2 border-gray-1`,
        primary: tw`text-primary border-primary`,
        secondary: tw`text-primary-hover border-primary-hover`,
      },
      colorHover: {
        default: tw`hover:(bg-gray-0 text-gray-2 border-gray-0)`,
        primary: tw`hover:(bg-primary text-white border-primary)`,
        secondary: tw`hover:(bg-primary-hover text-white border-primary-hover)`,
      },
      colorSelected: {
        default: tw`text-gray-2 bg-gray-0 border-gray-0`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
    },
    noHover: {
      color: {
        default: tw`text-gray-2 bg-gray-0 border-gray-0`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
      colorHover: {
        default: tw`text-gray-2 bg-gray-0 border-gray-0`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
      colorSelected: {
        default: tw`text-gray-2 bg-gray-0 border-gray-0`,
        primary: tw`text-white bg-primary border-primary`,
        secondary: tw`text-white bg-primary-hover border-primary-hover`,
      },
    },
  };

  const currentVariant = variantStyle[variant];
  const twStyle = [
    {
      ...tw`inline-block px-2 py-1 text-sm align-middle transition-colors duration-300 rounded-md`,
      ...currentVariant.main,
      ...currentVariant.color[color],
    },
    onClick !== null && [
      tw`transition-colors duration-300`,
      variant !== 'noHover' && tw`cursor-pointer`,
      currentVariant.colorHover[color],
    ],
    isSelected && [currentVariant.colorSelected[color]],
  ];

  const divProps = {
    css: cssMerge({ defaultCss: twStyle, ...styles }),
    'data-cy': 'tag',
    ...restProps,
  };

  const iconProps = {
    css: tw`inline mb-0.5 ml-1 w-2.5 h-2.5  cursor-pointer fill-current`,
  };

  if (!isEmpty(iconSrc)) {
    return (
      <div {...divProps} onClick={onClick}>
        <div tw="flex items-center">
          <Image src={iconSrc} width={20} height={20} />
          <p tw="ml-1">{label}</p>{' '}
          {isRemovable && <FontAwesomeIcon {...iconProps} />}
        </div>
      </div>
    );
  }

  return (
    <div {...divProps} onClick={onClick}>
      {label} {isRemovable && <TimesIcon {...iconProps} />}
    </div>
  );
};

Tag.displayName = 'Tag';

Tag.propTypes = {
  clickable: PropTypes.bool,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'primary-light',
    'primary-dark',
  ]),
  deleteIcon: PropTypes.node,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.node,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'small']),
  variant: PropTypes.oneOf(['default', 'outline']),
};

export default Tag;
